import { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import Menu from '../components/Menu';
import authContext from '../contexts/auth/authContext';
import globalContext from '../contexts/satnica/globalContext';
import Loader from '../components/Loader';

const PrivateRoutes = () => {
  const AuthContext = useContext(authContext);
  const GlobalContext = useContext(globalContext);
  const { user } = AuthContext;
  const { companyDetails, loading } = GlobalContext; // assuming loader is part of globalContext
  const { companyName } = companyDetails;
  // Show loader until authentication and global data are ready
  if (loading && !companyName && !user) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  // If authenticated and companyName is available, load protected content
  return user ? (
    <div
      className='mb-5'
      style={{ border: '1px solid #eee', minHeight: '400px' }}
    >
      <h2
        className='text-2xl text-center py-4 text-gray-900 font-sans font-normal'
        style={{ borderBottom: '1px solid #eee', minHeight: '65px' }}
      >
        {companyName}
      </h2>
      <Menu />
      <Outlet />
    </div>
  ) : (
    <Navigate to='/' />
  );
};

export default PrivateRoutes;
