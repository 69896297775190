import { useContext } from 'react';
import { registerUser } from '../api/api';
import { signupFields } from '../constants/formFields';
import alertContext from '../contexts/alert/alertContext';
import FormAction from './FormAction';
import Input from './Input';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

const fields = signupFields;

export default function Signup() {
  const AlertContext = useContext(alertContext);
  const { setAlert } = AlertContext;

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Unesite validan format e-maila')
      .required('Email je obavezan'),
    password: Yup.string()
      .min(6, 'Lozinka mora imati najmanje 6 karaktera')
      .required('Lozinka je obavezna'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Lozinke se moraju poklapati')
      .required('Potvrda lozinke je obavezna'),
    companyName: Yup.string().required('Naziv firme je obavezan'),
    pricingPlan: Yup.number().required('Odaberite plan cijena'),
  });

  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        confirmPassword: '',
        companyName: '',
        pricingPlan: 5, // Default option for the pricing plan
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const { email, password, companyName, pricingPlan } = values;
        registerUser(
          email,
          password,
          companyName,
          pricingPlan,
          navigate,
          setAlert
        );
      }}
    >
      {({ handleChange, values, errors, touched }) => (
        <Form className='space-y-6'>
          <div className='pt-1'>
            <div className='my-3'>
              <label
                htmlFor='pricingPlan'
                className='block text-sm font-medium text-gray-700 mb-2'
              >
                Odaberite plan
              </label>
              <select
                name='pricingPlan'
                id='pricingPlan'
                value={values.pricingPlan}
                onChange={handleChange}
                className='mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                required
              >
                <option value='5'>5 radnika(110km) god.</option>
                <option value='12'>12 radnika(170km) god.</option>
                <option value='25'>25 radnika(250km) god.</option>
                <option value='40'>40 radnika(370km) god.</option>
              </select>
              {touched.pricingPlan && errors.pricingPlan && (
                <span className='text-red-700 text-sm p-0 m-0'>
                  {errors.pricingPlan}
                </span>
              )}
            </div>
            {fields.map((field) => (
              <div key={field.id} className='my-3'>
                <Input
                  handleChange={handleChange}
                  value={values[field.id]}
                  labelText={field.labelText}
                  labelFor={field.labelFor}
                  id={field.id}
                  name={field.id}
                  type={field.type}
                  isRequired={field.isRequired}
                  placeholder={field.placeholder}
                />
                {touched[field.id] && errors[field.id] && (
                  <span className='text-red-700 text-sm p-0 m-0'>
                    {errors[field.id]}
                  </span>
                )}
              </div>
            ))}
          </div>
          <FormAction text='Registriraj se' />
        </Form>
      )}
    </Formik>
  );
}
